<template>
    <div class="show collapse" id="collapseExample">
        <div class="filter-content">
            <form @submit.prevent="onInvoiceFilterSubmit">
                <div class="form-row">
                    <div class="form-group col-lg-7">

                        <label class="col-form-label">{{ activeLanguage.title }}</label>

                        <div id="event_period">

                            <div class="form-row">
                                <div class="form-group col-sm-6 col-md-4">
                                    <label class="sr-only" for="dateFrom">{{ activeLanguage.from }}</label>
                                    <div class="input-group">
                                        <!--<div class="input-group-addon"><i class="fa fa-calendar" aria-hidden="true"></i></div>-->
                                        <!--<input v-model="form.dateFrom" type="text" class="form-control actual_range" id="dateFrom" data-provide="datepicker" placeholder="From">-->
                                        <Datepicker
                                            v-model="dateFrom"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.from"
                                            :highlighted="highlighted"
                                            format="dd-MM-yyyy"
                                            :language="langActive"
                                            :clear-button="true"
                                        />
                                    </div>

                                </div>
                                <div class="form-group col-sm-6 col-md-4" style="padding-left: 0 !important;padding-right: 12px;">
                                    <label class="sr-only" for="dateTo">{{ activeLanguage.to }}</label>
                                    <div class="input-group">
                                        <!--<div class="input-group-addon"><i class="fa fa-calendar" aria-hidden="true"></i></div>-->
                                        <!--<input v-model="form.dateTo" type="text" class="form-control actual_range" id="dateTo" data-provide="datepicker" placeholder="To">-->
                                        <Datepicker
                                            v-model="dateTo"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.to"
                                            format="dd-MM-yyyy"
                                            :language="langActive"
                                            :clear-button="true"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                   
                                </div>
                                <div class="form-group col-sm-6 col-md-4" v-if="manageOrdersPermission || isSuperUser" style="padding-right: 10px;padding-left: 9px;">
                                        <div class="form-row">
                                            <label for="input6" class="col-form-label">{{ activeLanguage.client }}</label>
                                            <v-autocomplete
                                                :min-len="2"
                                                v-model="client"
                                                :items="clients"
                                                :get-label="getLabel"
                                                :component-item='itemTemplate'
                                                @update-items="updateClientList"
                                                @item-selected="clientSelected"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </div>
                                     <div class="form-group col-sm-6" 
                                          :class="(manageOrdersPermission || isSuperUser) ? 
                                            'col-md-4 padd-r-14' : 'col-md-4 padd-l-7 padd-r-11 padd-l-8'">
                                        <div class="form-row">
                                            <label for="input6" class="col-form-label">{{ activeLanguage.searchText }}</label>
                                            <input
                                                class="form-control col-md-12"
                                                type="text"
                                                aria-label="Search"
                                                v-model="form.invoice"
                                            >
                                        </div>
                                    </div>
                                <div class="form-group col-12 filter-btn-pos">
                                    <button type="submit" class="btn mr-1">{{ activeLanguage.applyBtn }}</button>
                                    <button type="button" @click="clearInvoiceFilter" class="btn btn-secondary">{{ activeLanguage.clearBtn }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="form-group col-lg-5 col-12">-->
                        <!--<div class="payout-container">-->
                            <!--<img src="/img/icons/payout.svg" class="payout-img">-->
                            <!--<div class="payout">Payout <strong>10-12-2016</strong> to <strong>09-01-2017</strong> <span>€ 3.600,95</span></div>-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
            </form>

        </div>
    </div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';
	import {mapGetters} from  "vuex";

	import ClientItemTemplate from '../common/reusable/ClientItemTemplate.vue'
    import { en, nl } from 'vuejs-datepicker/dist/locale'
    import  Helpers from '../../helpers/modules/scripts'
    
	export default {
		name: "invoices-filer",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
		},
        data() {
			return {
				clients: [],
				client: null,
                itemTemplate: ClientItemTemplate,
				form: {},
				dateFrom: null,
                dateTo: null,
                langDate: null,
                nl: nl,
                en: en,
        highlighted: {
          dates: [
            new Date()
          ]
        }
            }
        },
		components: {
			Datepicker,
			ClientItemTemplate
        },
        mounted() {
            this.nl.months = Helpers.uppercaseArr(this.nl.months)
            this.nl.monthsAbbr = Helpers.uppercaseArr(this.nl.monthsAbbr)
            this.nl.days = Helpers.uppercaseArr(this.nl.days)
        },
		watch: {
			client: function(newVal, oldVal) {
				if (!newVal) { delete this.form.client_id }
			}
		},
		computed: {
			...mapGetters({
				manageOrdersPermission: 'userManage_orders',
				isSuperUser: 'isSuperUser',
				computedConnectedUsers: 'getComputedConnectedUsers',
                btoken: 'getBillinkToken',
                getActiveLanguageName: 'getActiveLanguageName'
            }),
            langActive () {
                return this.getActiveLanguageName === 'english' ? this.en : this.nl
            }
		},
        methods: {
	        fillFilterOptions(data) {
		        if (data.dateFrom) {
			        let dateFrom = data.dateFrom.split('-')
			        this.dateFrom = new Date(dateFrom[2], dateFrom[1] - 1, dateFrom[0])
		        }
		        if (data.dateTo) {
			        let dateTo = data.dateTo.split('-')
			        this.dateTo = new Date(dateTo[2], dateTo[1] - 1, dateTo[0])
		        }

		        this.form = data
            },
	        updateClientList(val) {
		        if (this.isSuperUser) {
			        this.$store.dispatch('startLoader')
			        this.clients = Helper.superUserFilter(val, this.computedConnectedUsers)
			        this.$store.dispatch('endLoader')
		        } else {
			        billinkAxios
				        .post('app/clients/list', {username: val})
				        .then(({data}) => this.clients = data.clients)
				        .catch(err => console.error(err))
		        }
	        },
	        clientSelected(val) {
		        this.client = val
		        this.form.client_id = val.id
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
	        onInvoiceFilterSubmit() {
		        if (this.dateFrom) { this.form.dateFrom = moment(this.dateFrom).format('DD-MM-YYYY') }
		        if (this.dateTo) { this.form.dateTo = moment(this.dateTo).format('DD-MM-YYYY') }
	        	this.$emit('onFiltersApply', this.form)
            },
	        onDateChange(date, type) {
	        	// console.log(date);
		        // date ? this.form[type] = moment(date).format('DD-MM-YYYY') : delete this.form[type]
	        },
	        clearInvoiceFilter() {
	        	this.client = null
		        this.form = {}
		        this.dateFrom = null
		        this.dateTo = null
		        this.$emit('clearNum')
		        this.$emit('onFiltersApply', {})
            }
        }
	}
</script>

<style scoped lang="scss">
    .payout-container {
        max-width: 100%;
    }
    @media (max-width: 992px) {
        .payout-container {
            margin: 0 auto;
            float: none;
        }
    }
    @media (max-width:768px) {
        .filter-btn-pos {
            display: flex;
            justify-content: space-between;
        }
    }
</style>
<style>
    .vdp-datepicker {
        z-index: 1000;
    }
    .mg-l-50 {
       margin-left: 50px; 
    }
    .padd-l-7 { padding-left: 7px !important; }
    .padd-r-11 { padding-right: 11px !important; }
    .padd-r-14 { padding-right: 14px !important; }
    .padd-l-8 { padding-left: 8px !important; }
</style>